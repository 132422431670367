import React, { useState, useLayoutEffect } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "styled-components"
import {
    SectionHeading,
  } from "../styledComponents/section"
import {
    EventListSection, 
    EventListSectionHeader,
    EventListSectionLede,
    EventListWrapper,
    EventList,
    EventListItem,
    EventInfo,
    EventInfoDateWrapper,
    EventInfoDateMonth,
    EventInfoDateDay,
    EventInfoDescription,
    EventRegisterButtonWrap,
    EventRegisterButton,
    EventListArrows,
    EventListArrowItem,
    EventListDot,
    EventListItemDot,
} from "../styledComponents/eventList"
import { StyledCloseModalButton } from "../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { StyledModal } from "../styledComponents/modal"
import { useSwipeable } from "react-swipeable"
import Icon from "../components/icon"
import SkusLevel1 from '../components/Products/SkusLevel1'
import SkusLevel1Discount20 from "../components/Products/SkusLevel1Discount20"
import SkusLevel1Discount30 from "../components/Products/SkusLevel1Discount30"
import SkusMasteryProgram from '../components/Products/SkusMastery'
import SkusCoachingProgram from '../components/Products/SkusCoachingProgram'
import CartOverview from '../components/Cart/CartOverview'
import KaarmiBeingLogo from "../components/kaarmiBeingLogo"

function FancyModalButton( data) {
  
  const [isDiscountSet, setIsDiscountCookieSet] = useState(false)
  const [discountValue, getDisocuntCookieValue] = useState(false)
  const [couponCodeInQuery, setCouponCodeInQuery] = useState(false)
  const checkDiscountCookieValue = (name) => {
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie) 
    const earlyBirdDiscountName = unescape(value[1])
    getDisocuntCookieValue(earlyBirdDiscountName)
  }

  const checkIfDiscountParam = () => {
    document.cookie
    .split(";")
    .some(item => item.trim().startsWith("eb="))
    ? checkDiscountCookieValue('eb')
    : setIsDiscountCookieSet(false)
  }

const checkIfCouponCodeInQuery = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const couponCode = urlParams.get('cc')
  setCouponCodeInQuery(couponCode)
}

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfDiscountParam()
      checkIfCouponCodeInQuery()
      return
    }
  }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
    
    let skuList
    if((data.data === 'Level 1') || (data.data === 'Homepage') ){
      // if(discountValue === 'f3Hj2ob221' || couponCodeInQuery === 'f3Hj2ob221'){
      //   skuList = <SkusLevel1Discount20 />
      // }
      // else if(discountValue === '0Ijss23Nmx1' || couponCodeInQuery === '0Ijss23Nmx1'){
      //   skuList = <SkusLevel1Discount30 />
      // }
      // else {
        skuList = <SkusLevel1 />
      // }
    }
    else if(data.data === 'Mastery'){
      skuList = <SkusMasteryProgram />
    }
    else if(data.data === 'Personal Consultation'){
      skuList = <SkusCoachingProgram />
    }
    else if(data.data === 'Free trial'){
      skuList = <SkusLevel1 />
    }

    return (
      <>
        <EventRegisterButtonWrap>
          <EventRegisterButton onClick={toggleModal}>Register</EventRegisterButton>
        </EventRegisterButtonWrap>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className="order-modal"
        >
          <StyledCloseModalButton onClick={toggleModal}>
           <Icon name="nav" />
          </StyledCloseModalButton>
          <h2><KaarmiBeingLogo /></h2>
          {skuList}
          <CartOverview />
        </StyledModal>
      </>
    );
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
  `;

const Heading = ({ level = 1, location, children }) => (
    <SectionHeading as={`h${level}`} level={level} location={location}>
      {children}
    </SectionHeading>
  )

const EventListData = ({ data, page }) => {
    const breakpoints = useBreakpoint()
    const [currentEventIndex, setCurrentEvent] = useState(0)
    const totalEvents = data.wpPage.eventsData.events.length
    const handlers = useSwipeable({
        onSwipedLeft: () => swipe(currentEventIndex, "left"),
        onSwipedRight: () => swipe(currentEventIndex, "right"),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
    })

    const swipe = (currentEventIndex, direction) => {
        currentEventIndex = parseInt(currentEventIndex)
        if (currentEventIndex === 0) {
        return direction === "left"
            ? setCurrentEvent(currentEventIndex + 1)
            : setCurrentEvent(0)
        }

        if (currentEventIndex + 1 === data.wpPage.eventsData.events.length) {
        return direction === "left"
            ? setCurrentEvent(currentEventIndex)
            : setCurrentEvent(currentEventIndex - 1)
        }

        if (currentEventIndex > 0) {
        return direction === "left"
            ? setCurrentEvent(currentEventIndex + 1)
            : setCurrentEvent(currentEventIndex - 1)
        }
    }

    return (
        <>
        <EventListSection name="our-events" noPadding page={page}>
            <EventListSectionHeader>
                <Heading level={2}>
                    Upcoming {page} webinars
                </Heading>
                <EventListSectionLede>
                    Join us in one of our upcoming <strong>life changing</strong> {page} live <strong>webinars</strong>
                </EventListSectionLede>
            </EventListSectionHeader>

            <EventListWrapper>
                <EventList
                {...handlers}
                totalEvents={parseInt(data.wpPage.eventsData.events.length)}
                index={parseInt(currentEventIndex)}
                >
                {data.wpPage.eventsData.events.map((event, index) => {
                    let dateSplit = event.date.split(/ /);
                    
                    return (
                        <EventListItem isInFocus={parseInt(currentEventIndex) === index} key={`${index}-${event.citationName}`}>
                            <EventInfo>
                                <EventInfoDateWrapper>
                                    <EventInfoDateMonth>{dateSplit[1].substring(0,3)}</EventInfoDateMonth> 
                                    <EventInfoDateDay>{dateSplit[2].replace(/(\d+)(st,|nd,|rd,|th,)/, "$1")}</EventInfoDateDay>
                                </EventInfoDateWrapper>
                                <EventInfoDescription>
                                    <p>{breakpoints.xs || breakpoints.sm ? dateSplit[0].substring(0,3) : dateSplit[0]} {dateSplit[2].replace(/,/g, '')} {event.time.replace(/ /g, '')} {event.timezone}</p>
                                    <p>Where: {event.location}</p>
                                </EventInfoDescription>
                                {/*  */}
                                <ModalProvider backgroundComponent={FadingBackground}>
                                    <FancyModalButton data={data.wpPage.title}/>
                                </ModalProvider>
                            </EventInfo>
                        </EventListItem>
                    )
                })}
                </EventList>
                {totalEvents > '1' && (
                    <EventListDot>
                    {data.wpPage.eventsData.events.map((event, index) => {
                        return (
                        <EventListItemDot key={index}>
                            <label htmlFor={event.citationName}>
                            <input
                            type="radio"
                            id={event.citationName}
                            checked={parseInt(currentEventIndex) === index}
                            name="event"
                            value={index}
                            onChange={e => setCurrentEvent(e.target.value)}
                            />
                            </label> 
                        </EventListItemDot>
                        )
                    })}
                    </EventListDot>
                )}

                {totalEvents > '1' && (
                <EventListArrows>
                    <EventListArrowItem>
                    <button onClick={() => swipe(currentEventIndex, "right")} disabled={parseInt(currentEventIndex) === 0} >
                        <svg
                        width="82"
                        height="82"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g>
                            <circle
                            cx="41"
                            cy="41"
                            r="41"
                            transform="rotate(-180 41 41)"
                            fill="#fff"
                            />
                            <path
                            d="M44.53 28c.368 0 .735.147 1.03.44a1.462 1.462 0 010 2.093L35.6 40.482l9.958 9.985a1.462 1.462 0 010 2.093 1.465 1.465 0 01-2.094 0L32.44 41.546A1.45 1.45 0 0132 40.518c0-.404.147-.77.44-1.028l11.025-11.013c.33-.33.698-.477 1.065-.477z"
                            fill="#3D3B37"
                            />
                        </g>
                        </svg>
                    </button>
                    </EventListArrowItem>
                    <EventListArrowItem>
                    <button onClick={() => swipe(currentEventIndex, "left")} disabled={parseInt(currentEventIndex + 1) === totalEvents}  >
                        <svg
                        width="82"
                        height="82"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g>
                            <circle
                            cx="41"
                            cy="41"
                            r="41"
                            transform="rotate(-180 41 41)"
                            fill="#fff"
                            />
                            <path
                            d="M37.47 53c-.368 0-.735-.147-1.03-.44a1.462 1.462 0 010-2.093l9.959-9.949-9.958-9.985a1.462 1.462 0 010-2.093 1.465 1.465 0 012.094 0L49.56 39.454c.294.293.441.66.441 1.028 0 .404-.147.77-.44 1.028L38.534 52.523c-.33.33-.698.477-1.065.477z"
                            fill="#3D3B37"
                            />
                        </g>
                        </svg>
                    </button>
                    </EventListArrowItem>
                </EventListArrows>
                )}
            </EventListWrapper>
        </EventListSection>
      </>
    )
  }
  
  export default EventListData
  